<template>
  <section>
    <b-card class="card-browser-states">
      <b-card-body>
        <!--
        <b-row class="ll-hr">
          <b-col lg="3">
            <p class="l-pCon">{{pkSn}}</p>
            <p class="l-pTit">{{ $t('Seq No') }}.</p>
          </b-col>
          <b-col lg="3">
            <p class="l-pCon">{{netWeight}}</p>
            <p class="l-pTit">{{ $t('Net weight') }}</p>
          </b-col>
          <b-col lg="3">
            <p class="l-pCon">{{value}}</p>
            <p class="l-pTit">{{ $t('Goods value') }}</p>
          </b-col>
          <b-col lg="3">
            <p class="l-pCon">{{demension}}</p>
            <p class="l-pTit">{{ $t('Demension') }} (L x W x H)</p>
          </b-col>
        </b-row>
        -->
        <b-table :items="item1" :fields="fields1"
          :borderless='false' :hover="true"
          :sticky-header="true"
          class="ll-tableHei">
          <template #cell(title)="scope">
            <p class="ll-mulP">{{ scope.item.productId }}</p>
            <p class="ll-mulP">{{ scope.value }}</p>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
    BCard, BCardBody, BTable,
    BRow, BCol,
} from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
  },
  props: {
    jobDetails1: Object,
    jobDetails2: Object,
    jobDetails5: Array,
  },
  data() {
    return {
      item1: [],
      fields1: [
        { key: 'title', label: this.$t('Item') },
        // { key: 'productId', label: '' },
        { key: 'quantity', label: this.$t('Quantity') },
        { key: 'unitPrice', label: this.$t('Unit Price') },
        { key: 'currency', label: this.$t('Currency') },
        { key: 'hsCode', label: this.$t('HSCode') },
        { key: 'customDesc', label: this.$t('Custom Desc') },
        { key: 'origin', label: this.$t('Origin') },
      ],
      seqNo: '',
      netWeight: '',
      value: '',
      demension: '',
      tableHei: '',
      windowHeight: document.documentElement.clientHeight*0.67,
      pkSn: null,
      condition: {
        page: '1',
        size: '10',
        trackingIDs: null,
        orderBy: 'id',
        orderMole: 1,
        isAmazon: false,
      },
    }
  },
  computed: {
  },
  watch: {
    jobDetails1(val){
      this.getinfo1(val)
    },
    jobDetails2(val){
      this.getinfo2(val)
    },
    jobDetails5(val){
      this.getinfo3(val)
    },
  },
  created() {},
  methods: {
    getinfo1(v){
      const info = v.packages[0]
      this.value = (info.value !== null) ? info.value : 0
      this.pkSn = (info.pkSn !== null) ? info.pkSn : 0
    },
    getinfo2(info){
      this.netWeight = (info.actWeight !== null) ? info.actWeight : 0
      if (info.actLength !== null || info.actWidth !== null || info.actHeight !== null) {
        this.demension = `${info.actLength} X ${info.actWidth} X ${info.actHeight} `
      } else {
        this.demension = '0 X 0 X 0'
      }
    },
    getinfo3(info){
      this.item1 = info
    },
  },
}
</script>
<style scoped>
.l-pCon{
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  margin-bottom: 0;
}
/* .l-pTit{
  height: 36px;
} */
.ll-mulP{
  margin: 0;
}
.ll-hr{
  border-bottom: 1px solid #d0d2d6;
  margin-bottom: 10px;
}
.ll-tableHei{
  height: 40vh;
  max-height: unset;
}
</style>
