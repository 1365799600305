<template>
  <section>
    <b-card
      no-body
      class="card-browser-states ll-c ll-hei"
    >
      <b-card-header>
        <b-card-title>{{ $t('Destination Location') }}</b-card-title>
        <div>
        <b-button
          variant="danger"
          class="mr-1"
          @click="openDel"
        >
          <span>{{ $t('Cancel') }} {{ $t('Order') }}</span>
        </b-button>
        <b-button
          v-b-toggle.sidebar
          id="popover-reactive-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          v-permission="[142]"
        >
          <span>{{ $t('Edit') }}</span>
        </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="4">
            <div class="ll-map" ref="map" :style="{'height': height+'px'}"></div>
          </b-col>
          <b-col lg="4">
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Service Party') }}:</label>
              <span class="ll-span" v-if="imgData !== '' && imgData !== null">
                <b-img
                  :src="imgData"
                  height="auto"
                  class="mr-1 ll-img"
                />
              </span>
              <span class="ll-span" v-else>{{sParty}}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('3rd Party Ref No') }}:</label>
              <span class="ll-span">{{ refNo }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Delivery Address') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.cneeAddress}}, {{jobBaseInfo.cneeCity}}</span>

              <!-- <span class="ll-span">{{ (jobBaseInfo.updAddress !== null) ? (jobBaseInfo.updAddress) : caddress}}</span> -->
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Consignee Phone') }}:</label>
              <span class="ll-span">{{ cphone }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Delivery Time') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.deliveryTime}}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Remark') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.updRemark}}</span>
            </div>
            <!-- <div class="ll-box">
              <label class="ll-lab">{{ $t('City') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.cneeCity}}</span>
            </div> -->
          </b-col>
          <b-col lg="4">
            <div class="ll-box">
              <label class="ll-lab">{{ $t('District') }}:</label>
              <span class="ll-span">{{ cdistinct }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Sorting Code') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.sortCode }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('SC Sorting Code') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.scSortCode }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('SC Sorting Code(footer)') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.scSortCode2 }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">{{ $t('Package Description') }}:</label>
              <span class="ll-span">{{ jobBaseInfo.pkDesc}}</span>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="card-browser-states ll-c ll-hei"
    >
      <b-card-header>
        <b-card-title class="ll-title">{{ $t('Shipment status tracking') }}</b-card-title>
        <div>
          <b-button
            variant="flat-secondary"
            @click="pushStatus"
          >
            <feather-icon icon="SendIcon" />
          </b-button>
          <b-button
            variant="flat-secondary"
            @click="getStatus"
          >
            <feather-icon icon="RefreshCcwIcon" />
          </b-button>
        </div>
        
      </b-card-header>
      <b-card-body class="ll-cb">
        <b-table :items="items" :fields="fields"
          :borderless='false' :hover="true"
          :sticky-header="true"
          class="ll-tableHei"
          :style="styleVar"
        >
          <template #cell(externalStatusCode)="scope">
            <span>{{(scope.value !== null && scope.value !== '' && scope.item.externalStatusCode2 !== null && scope.item.externalStatusCode2 !== '') ? (scope.value+' /') : scope.value}} {{scope.item.externalStatusCode2}}</span>
          </template>
          <template #cell(statusType)="scope">
            <!-- <feather-icon
              :icon="(scope.value==='NORMAL') ? icon1 : icon2"
              size="16"
              :class="(scope.value==='NORMAL') ? color1 : color2"
            /> -->
            <svg class="ll-ficon" aria-hidden="true" v-if="scope.value==='NORMAL'">
              <use xlink:href="#icon-form_icon_normal"></use>
            </svg>
            <svg class="ll-ficon" aria-hidden="true" v-if="scope.value==='EXCEPTION'">
              <use xlink:href="#icon-form_icon_disable"></use>
            </svg>
            <svg class="ll-ficon" aria-hidden="true" v-if="scope.value==='MESSAGE'">
              <use xlink:href="#icon-icon-test"></use>
            </svg>
          </template>
          <template #cell(statusDesc)="scope">
            <span>
              {{scope.value}} {{scope.item.originStatusDesc!== null ? '('+scope.item.originStatusDesc+')' : null}}
            </span>
          </template>
        </b-table>
      </b-card-body>
    </b-card>

    <!-- routeing sidebar -->
    <b-sidebar id="sidebar" title="" v-model="sidebar"
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3 py-2 ll-py">
        <validation-observer ref="rules">
          <b-tabs
            class="ll-tabs"
            fill
          >
            <b-tab title="UPDATE SORT CODE" active>
          <!-- <b-card-title style="margin-bottom: 2rem;">{{ $t('Update') }} {{ $t('Sort Code') }}</b-card-title> -->
              <div class="ll-checkbox">
                <b-row>
                  <b-col lg="4">
                    <span>{{ $t('Home delivery') }}</span>
                  </b-col>
                  <b-col lg="4">
                    <b-form-checkbox
                      checked="true"
                      class="custom-control-info ll-switch"
                      name="check-button"
                      switch
                      v-model="ischeck"
                      @input="selhd"
                    />
                  </b-col>
                  <b-col lg="4">
                    <span>{{ $t('Self-pick') }}</span>
                  </b-col>
                </b-row>
              </div>
              <b-form-group
                :label="$t('Select a delivery area')"
                label-for="area"
                v-if="ishd"
              >
                <validation-provider
                  #default="{ errors }"
                  name="area"
                  rules="required"
                >
                  <v-select
                    id="area"
                    :options="areaList"
                    label="routeArea"
                    v-model="area"
                    placeholder="Please select area"
                    @input="getArea"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Select a district')"
                label-for="district"
                v-if="isDis"
              >
                <validation-provider
                  #default="{ errors }"
                  name="district"
                  rules="required"
                >
                  <v-select
                    id="district"
                    :options="districtList"
                    label="routeDistrict"
                    v-model="district"
                    placeholder="Please select district"
                    @input="getDistrict"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Select a routing code')"
                label-for="routeCode"
                v-if="isRcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="routeCode"
                  rules="required"
                >
                  <v-select
                    id="routeCode"
                    :options="routeCodeList"
                    label="sortingCode"
                    v-model="editRouting.sortCode"
                    placeholder="Please select routing code"
                    @input="getRouteCode"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Select a vendor')"
                label-for="vendor"
                v-if="issp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="vendor"
                  rules="required"
                >
                  <v-select
                    id="vendor"
                    :options="vendorList"
                    label="agent"
                    v-model="vendor"
                    placeholder="Please select vendor"
                    @input="getVendor"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Location code')"
                label-for="district"
                v-if="isLocCode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location code"
                  rules="required"
                >
                  <v-select :options="locCodeList" label="locationID" @input="selCode" v-model="locCode"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Location name')"
                label-for="area"
                v-if="isLocName"
              >
                <b-form-input v-model="locName"/>
              </b-form-group>
              <div class="ll-btn">
                <b-button
                  variant="secondary"
                  class="mr-1"
                  @click="onClear"
                  style="width:200px"
                >
                  {{ $t('Clear') }}
                </b-button>
                <b-button
                  v-b-toggle.sidebar
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click.prevent="checkRouting"
                  style="width:200px"
                >
                  {{ $t('Save') }}
                </b-button>
              </div>
            </b-tab>
            <b-tab title="UPDATE CONSIGNEE INFORMATION">
              <b-card-title style="margin-bottom: 2rem;">{{ $t('Update') }} {{ $t('Consignee Address') }}</b-card-title>
              <b-form-group
                :label="$t('New Address')"
                label-for="s"
              >
                <b-form-input v-model="editRouting.updAddress"/>
              </b-form-group>
              <b-form-group
                :label="$t('Update Remark')"
                label-for="s"
              >
                <b-form-input v-model="editRouting.updRemark"/>
              </b-form-group>
              <hr style="margin: 2rem 0;">
              <b-card-title style="margin-bottom: 2rem;">{{ $t('Update') }} {{ $t('Consignee Phone') }}</b-card-title>
              <b-form-group
                :label="$t('Original Phone')"
                label-for="s"
              >
                <b-form-input v-model="cphone" disabled/>
              </b-form-group>
              <b-form-group
                :label="$t('New Phone')"
                label-for="s"
              >
                <b-form-input v-model="editRouting.consigneePhone"/>
              </b-form-group>
              <div class="ll-btn">
                <b-button
                  variant="secondary"
                  class="mr-1"
                  @click="onClear"
                  style="width:200px"
                >
                  {{ $t('Clear') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click.prevent="consigneeSave"
                  style="width:200px"
                >
                  {{ $t('Save') }}
                </b-button>
              </div>
            </b-tab>
          </b-tabs>
        </validation-observer>
      </div>
    </b-sidebar>
    <!-- Cancel Order-->
    <b-modal
      id="modal-del"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="isCancelBox"
      size="sm"
    >
      <div class="text-center">
        <svg aria-hidden="true" class="ll-delicon">
          <use xlink:href="#icon-cancel1"></use>
        </svg><p/>
        <p style="font-size:18px;"><b>Cancel Order</b></p>
        <p>You are cancelling an orders<br>
          <b>This action cannot be undone</b>
        </p>
        <!-- <span>Type 'cancel' to confirm</span> -->
        <!-- <b-form-input class="mt-1" v-model="orderNum"/> -->
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            variant="outline-secondary"
            class="btn mr-2"
            @click="isCancelBox = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="btn"
            @click="delOrder"
          >
            {{ $t('Confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BTable, BRow, BCol, BImg,
  BSidebar, VBToggle, BButton, BFormGroup, BFormInput, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BRow,
    BCol,
    BImg,
    BSidebar,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    permission,
  },
  props: {
    jobDetails1: Object,
    jobDetails2: Object,
    jobDetails3: Array,
  },
  data() {
    return {
      required,
      items: [],
      fields: [
        { key: 'statusType', label: '' },
        { key: 'eventTime', label: this.$t('Time Updated') },
        { key: 'statusCode', label: this.$t('StatusNo') },
        { key: 'statusDesc', label: this.$t('Description') },
        { key: 'externalStatusCode', label: this.$t('External Code') },
        { key: 'updateSource', label: this.$t('Source') },
        { key: 'operator', label: this.$t('Operator') },
        { key: 'refTkID', label: this.$t('Ref No') },
      ],
      areaList: [],
      area: '',
      districtList: [],
      district: '',
      routeCodeList: [],
      editRouting: {
        id: null,
        sortCode: null,
        updRemark: null,
        updAddress: null,
        isSelfPick: false,
        locationID: null,
        consigneePhone: null,
      },
      sParty: '',
      imgData: '',
      longitude: 114.132294,
      latitude: 22.360371,
      jobBaseInfo: {
        tkID: '',
        latestOrderStatus: '',
        orderID: '',
        mfNo: '',
        sortCode: '',
        attempts: '',
        serviceParty: '',
        mawb: '',
        numberOfReturn: '',
        customerOrderNumber: '',
        pkShippingDate: '',
        ead: '',
        scSortCode: '',
        scSortCode2: null,
        latitude: null,
        longitude: null,
        latestOrderStatusTime: '',
        updAddress: '',
        updRemark: '',
      },
      condition: {
        page: '1',
        size: '10',
        trackingIDs: null,
        orderBy: 'id',
        orderMole: 1,
        isAmazon: false,
      },
      refNo: '',
      caddress: '',
      cdistinct: '',
      cphone: '',
      jobTrack: {
        page: '1',
        size: '20',
        startEventTime: null,
        endEventTime: null,
        statusCode: null,
        orderBy: 'id',
        orderMole: '0',
        tkIDs: '',
      },
      icon1: 'CheckCircleIcon',
      icon2: 'AlertTriangleIcon',
      color1: 'icon-sussess',
      color2: 'icon-fail',
      tHei: document.documentElement.clientHeight*0.67,
      windowHeight: document.documentElement.clientHeight*0.67,
      isDis: false,
      isRcode: false,
      ischeck: false,
      ishd: true,
      issp: false,
      vendorList: [],
      vendor: '',
      height: document.documentElement.clientHeight*0.2,
      locCodeList: [],
      locName: null,
      isLocCode: false,
      isLocName: false,
      tkid: null,
      deliveryType: null,
      isCancelBox: false,
      orderNum: null,
      isShowBtn: true,
      isDelOk: true,
      customerOrderNo: null,
      sidebar:false,
    }
  },
  computed: {
    styleVar() {
      return {
        '--height': this.windowHeight*0.28 + 'px'
      }
    },
  },
  watch: {
    jobDetails1(val){
      this.getinfo1(val)
    },
    jobDetails2(val){
      this.getinfo2(val)
    },
    jobDetails3(val){
      this.getinfo3(val)
    },
  },
  created() {},
  mounted() {
    this.queryArea()
    this.initMaps1()
    this.queryVendorList()
  },
  methods: {
    openDel(){
      this.isCancelBox = true
    },
    delOrder() {
      this.$http.get('lmd/cancellationOfOrder', {params: {number:this.customerOrderNo}}).then(res=>{
        if (res.data.code == '200') {
          this.isCancelBox = false
          this.$emit('isDelOk', this.isDelOk)
        }
      })
    },
    getinfo1(info){
      this.caddress = info.caddress
      this.cdistinct = info.cdistinct
      this.cphone = info.cphone
    },
    getinfo2(info){
      this.customerOrderNo = info.customerOrderNo
      this.editRouting.id = info.id
      this.jobBaseInfo = info
      this.refNo = info.refTkID
      this.sParty = info.serviceParty
      this.jobBaseInfo.latitude = Number(info.latitude)
      this.jobBaseInfo.longitude = Number(info.longitude)
      this.tkid = info.tkID
      this.deliveryType = info.deliveryType
      if (this.sParty !== null) {
        this.querySParty()
      }
      this.onClickSidebar()
      this.initMaps()
      
    },
    getinfo3(info){
      this.items = info
    },
    pushStatus(){
      if (this.tkid){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Request success',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        let tkids = []
        tkids.push(this.tkid)
        this.$http.post('/test/kes/statusIn',tkids).then(res => {
          console.log(res)
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    // 刷新状态
    getStatus() {
      if (this.tkid){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Request success',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        let tkids = []
        tkids.push(this.tkid)
        //拉取EPOD的貨端
        this.$http.get('/lmd/epod/getStatus?tkID='+this.tkid).then(res => {
            console.log(res)
          })
        setTimeout(() => {
          this.$http.post('/debug/status/debugKesStatusOut',tkids).then(res => {
            console.log(res)
          })
        }, 3000)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    // 基本信息-根据TKID查询
    getDetail(tkid) {
      this.condition.trackingIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobkes/search', this.condition).then(res => {
        if (res.data.data.content.length !== 0){
          const info = res.data.data.content[0]
          // console.log('ss', info)
          this.editRouting.id = info.id
          this.jobBaseInfo = info
          // this.refNo = info.refTkID
          this.sParty = info.serviceParty
          this.jobBaseInfo.latitude = Number(info.latitude)
          this.jobBaseInfo.longitude = Number(info.longitude)
          this.initMaps()
          if (this.sParty !== null) {
            this.querySParty()
          }
        }
      })
    },
    // 基本信息-根据orderId查询
    getDetail1(oId) {
      this.$http.get('/jobkes/getjobDetails', { params: { orderId: oId } }).then(res => {
        const info = res.data.data
        // console.log('res1', info)
        this.caddress = info.caddress
        this.cdistinct = info.cdistinct
        // this.refNo = info.refNo
      }).catch(error => console.log(error))
    },
    // shipment status tracking
    statusTrack(tkid) {
      this.jobTrack.tkIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobTrack/search', this.jobTrack).then(res => {
        this.items = res.data.data.content
      })
    },
    // 地图
    initMaps1() {
      this.maps = new google.maps.Map(this.$refs.map, {
        zoom: 8,
        center: { lat: this.latitude, lng: this.longitude },
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        language: 'zh_cn',
      })
      this.marker = new google.maps.Marker({
        position: { lat: this.latitude, lng: this.longitude },
        map: this.maps,
      })
    },
    initMaps() {
      if (this.jobBaseInfo.latitude === 0 || this.jobBaseInfo.longitude === 0) {
        this.initMaps1()
      } else {
        this.maps = new google.maps.Map(this.$refs.map, {
          zoom: 17,
          center: { lat: this.jobBaseInfo.latitude, lng: this.jobBaseInfo.longitude },
          disableDefaultUI: true,
          gestureHandling: 'greedy',
          language: 'zh_cn',
        })
        this.marker = new google.maps.Marker({
          position: { lat: this.jobBaseInfo.latitude, lng: this.jobBaseInfo.longitude },
          map: this.maps,
        })
      }
    },
    // routing 弹框
    queryArea() {
      this.$http.get('routeList/getRouteAreaList').then(res => {
        this.areaList = res.data.data
      })
    },
    getArea(s) {
      this.area = s.routeArea
      if (this.area !== null) {
        // this.queryDistrict()
        // this.isDis = true
        this.queryRouteCode()
        this.isRcode =true
      }
    },
    queryDistrict() {
      const data = {}
      data.routeArea = this.area
      this.$http.post('routeList/getDistrictAndSortCodeList', data).then(res => {
        this.districtList = res.data.data
      })
    },
    getDistrict(s) {
      if (s) {
        this.district = s.routeDistrict
      }
      if (this.district !== null) {
        this.queryRouteCode()
        this.isRcode =true
      }
    },
    queryRouteCode() {
      const data = {}
      data.routeArea = this.area
      // data.routeDistrict = this.district
      this.$http.post('routeList/getDistrictAndSortCodeList', data).then(res => {
        this.routeCodeList = res.data.data
      })
    },
    getRouteCode(s) {
      if (s) {
        this.editRouting.sortCode = s.sortingCode
      }
    },
    checkRouting1() {
      return new Promise((resolve, reject) => {
        this.$refs.rules.validate().then(success => {
          if (success) {
            this.saveRouting()
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please check the data',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
            reject()
          }
        })
      })
    },
    checkRouting() {
      if (this.editRouting.updAddress === '') {
        this.checkRouting1()
      } else {
        this.saveRouting()
      }
    },
    consigneeSave(){
      if (this.editRouting.consigneePhone&&this.editRouting.consigneePhone.length < 8) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '手機號的輸入要大於8位',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return false;
      }
      let action = []
      if (this.editRouting.consigneePhone && this.editRouting.updAddress && this.editRouting.updRemark) {
        action = ["UPDATE_ADDRESS","UPDATE_PHONE","UPDATE_REMARK"]
      }else if (!this.editRouting.consigneePhone && this.editRouting.updAddress && this.editRouting.updRemark) {
        action = ["UPDATE_ADDRESS","UPDATE_REMARK"]
      }else if (this.editRouting.consigneePhone && !this.editRouting.updAddress && this.editRouting.updRemark) {
        action = ["UPDATE_PHONE","UPDATE_REMARK"]
      }else if (this.editRouting.consigneePhone && this.editRouting.updAddress && !this.editRouting.updRemark) {
        action = ["UPDATE_ADDRESS","UPDATE_PHONE"]
      }else if (!this.editRouting.consigneePhone && !this.editRouting.updAddress && this.editRouting.updRemark) {
        action = ["UPDATE_REMARK"]
      }else if (!this.editRouting.consigneePhone && this.editRouting.updAddress && !this.editRouting.updRemark) {
        action = ["UPDATE_ADDRESS"]
      }else if (this.editRouting.consigneePhone && !this.editRouting.updAddress && !this.editRouting.updRemark) {
        action = ["UPDATE_PHONE"]
      }
      let params = {
        tkID:this.tkid,
        address:this.editRouting.updAddress,
        phone:this.editRouting.consigneePhone,
        remark:this.editRouting.updRemark,
        action:action
      }
      this.$http.post('jobkes/editOrderInfo', params).then(res =>{
        // console.log(res.data)
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
      this.sidebar = false
    },
    onClear() {
      this.editRouting.updRemark = ''
      this.editRouting.updAddress = ''
      this.editRouting.sortCode = ''
      this.area = ''
      this.district = ''
    },
    editRoutingCode() {
      if (!this.editRouting.isSelfPick) {
        delete this.editRouting.serviceParty
      }
      if (!this.editRouting.locationID && !this.editRouting.sortCode) {
        this.editRouting.isSelfPick = null
      }
      this.$http.put('jobkes/editRoutingCode', this.editRouting).then(res =>{
        // console.log(res.data)
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    saveRouting() {
      if (this.editRouting.id !== null) {
        this.editRoutingCode()
        // if (this.deliveryType === 'SP' && !this.editRouting.isSelfPick) {
        //   this.$swal({
        //     title: '',
        //     text: this.$t('Are you sure to change self-pick to Home delivery'),
        //     icon: 'warning',
        //     confirmButtonText: 'Sure',
        //     showCancelButton: true,
        //     customClass: {
        //       confirmButton: 'btn btn-primary',
        //       cancelButton: 'btn btn-outline-danger ml-1',
        //     },
        //     buttonsStyling: false,
        //   }).then(result => {
        //     if (result.isConfirmed) {
        //       this.editRoutingCode()
        //     }
        //   })
        // } else {
        //   this.editRoutingCode()
        // }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    querySParty() {
      this.$http.get('admin/settings/organization/getByName', { params: { name: this.sParty}}).then(res=> {
        this.imgData = res.data.data
      })
    },
    selhd(d) {
      this.editRouting.isSelfPick = d
      this.editRouting.updAddress = null
      this.editRouting.updRemark = null
      if (d) {
        this.ishd = false
        this.isDis = false
        this.isRcode =false
        this.issp = true
      } else {
        this.ishd = true
        this.issp = false
        this.isLocCode = false
        this.isLocName = false
      }
    },
    queryVendorList() {
      this.$http.get('selfpickLocation/getAgentList').then(res=>{
        this.vendorList = res.data.data
      })
    },
    getVendor(d) {
      this.vendor = d.agent
      this.editRouting.serviceParty = d.agent
      this.locCode = null
      this.locName = null
      this.queryLocCode()
      if (this.vendor !== null) {
        this.queryLocCode()
        this.isLocCode = true
      }
    },
    queryLocCode() {
      this.$http.get('selfpickLocation/getListBySearch', {params: {agent:this.vendor}}).then(res=>{
        this.locCodeList = res.data.data
      })
    },
    selCode(d) {
      this.locCode = d.locationID
      this.editRouting.locationID = d.locationID
      if (this.locCode !== null) {
        this.queryLocName()
        this.isLocName = true
      }
    },
    queryLocName() {
      this.$http.get('selfpickLocation/getListBySearch', {params: {agent:this.pro, locationID:this.locCode}}).then(res=>{
        this.locName = res.data.data[0].storename
      })
    },
    onClickSidebar(){
      // this.editRouting.sortCode = null
      this.editRouting.updRemark = null
      this.editRouting.updAddress = null
      // this.editRouting.isSelfPick = null
      // this.editRouting.locationID = null
    }
  },
}
</script>
<style scoped>
.ll-c{
  margin-bottom: 5px;
}
.ll-c .card-header{
  font-weight: 500;
  font-size: 1.285rem;
}
.ll-c .card-body .card-title{
  font-weight: normal;
  /* font-size: 14px; */
}
.ll-map{
  width: 100%;
  /* height: 230px; */
  height: var(--height);
  margin-bottom: 10px;
}
.ll-box{
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 6px;
}
.ll-box .ll-lab{
  font-weight: 500;
  width: 35%;
}
.ll-box .ll-span{
  width: 65%;
  text-align: right;
}
.icon-sussess{
  color: #28c76f;
}
.icon-fail{
  color: #ff9f43;
}
.ll-tableHei{
  /* height: 274px; */
  height: var(--height);
  max-height: unset;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-checkbox{
  margin-bottom: 2rem;
}
.ll-checkbox span{
  font-weight: bold;
}
</style>
<style>
.ll-switch .custom-control-label::before{
  width: 100px;
  height: 2rem;
  top: -0.1rem;
  background-color: #409eff;
}
[dir=ltr] .ll-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(6.4rem);
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th{
  background-color: #fff;
}
[dir] .table thead th{
  border: none;
  padding: 5px;
}
.d-block{
  font-size: 14px;
}
[dir] .card .card-title {
  margin-bottom: 0.5rem;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 28%;
}
.d-block{
  font-size: 12px;
}
.b-sidebar-header strong{
  width: 100%;
  text-align: center;
}
.table td {
    padding: 0.72rem;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
.ll-img{
  width: 80px;
  height: 25px;
}
</style>
