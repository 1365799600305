<template>
    <section>
        <b-card
        no-body
        class="card-browser-states ll-c ll-hei"
        v-for="(item, index) in list" :key="index"
        >   
            <div  style="display:flex;line-height:30px;padding:20px">
                <div style="border-right:1px solid #d0d2d6;width:40%;padding:20px;padding-top:0">
                    <div class="ll-title">{{ $t('From Client') }}</div>
                    <div class="ll-span">
                        <div>Length:</div>
                        <div>{{item.pkLength}} cm</div>
                    </div>
                    <div class="ll-span">
                        <div>Width:</div>
                        <div>{{item.pkWidth}} cm</div>
                    </div>
                    <div class="ll-span">
                        <div>Height:</div>
                        <div>{{item.pkHeight}} cm</div>
                    </div>
                    <div class="ll-span">
                        <div>Weight:</div>
                        <div>{{item.pkNetWeight}} kg</div>
                    </div>
                </div>
                <div style="width:40%;padding:20px;padding-top:0">
                    <div class="ll-title">{{ $t('Actual') }}</div>
                    <div class="ll-span">
                        <div>Length:</div>
                        <div>{{item.actLength}} cm</div>
                    </div>
                    <div class="ll-span">
                        <div>Width:</div>
                        <div>{{item.actWidth}} cm</div>
                    </div>
                    <div class="ll-span">
                        <div>Height:</div>
                        <div>{{item.actHeight}} cm</div>
                    </div>
                    <div class="ll-span">
                        <div>Weight:</div>
                        <div>{{item.actWeight}} kg</div>
                    </div>
                </div>
                <div style="width:20% !important;margin-top:40px;">
                    <swiper
                        class="swiper-navigations"
                        :options="swiperOptions"
                        ref="swiperImg"
                    >
                        <swiper-slide
                        v-for="(data,index) in item.weightSnaps"
                        :key="index"
                        >
                        <b-img
                            :src="data"
                            fluid
                            style="height: 200px;width:100%"
                        />
                        </swiper-slide>
                        <!-- Add Arrows -->
                        <div
                        slot="button-next"
                        class="swiper-button-next"
                        />
                        <div
                        slot="button-prev"
                        class="swiper-button-prev"
                        />
                    </swiper>
                </div>
            </div>
        </b-card>
    </section>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BTable, BRow, BCol, BImg,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import "swiper/css/swiper.css"
export default {
    components:{
        BCard,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BImg,
        Swiper,
        SwiperSlide,
    },
    props:{
        packageInfo:Array
    },
    watch:{
        packageInfo(val){
            this.getInfo(val)
        }
    },
    data(){
        return{
            list:[],
            swiperOptions: {
                observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
        }
    },
    methods:{
        getInfo(val){
            this.list = val
        },
    }
}
</script>
<style scoped>
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 2rem;
}
.ll-title{
  font-weight: 500;
  font-size: 1.8rem;
}
.ll-span{
    display:flex;justify-content:space-between;font-size: 1.5rem;line-height: 40px;
}
</style>